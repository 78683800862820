<template>
  <router-link
    :to="'/parks/' + attraction.park_id + '/attractions/' + attraction.id"
    class="p-4 attraction-card"
  >
    <div class="w-full h-full bg-white border border-gray-300 rounded-lg relative overflow-hidden shadow-lg">
      <div class="flex flex-col h-full">
        <div class="flex-1 bg-cover" v-bind:style="'background-image: url(' + attraction.image + ');'">
          <div class="flex h-full bg-gradient-to-t from-black via-transparent to-transparent">
            <div class="w-full text-center text-white text-xl font-bold mt-auto p-4">
              {{ attraction.name }}
            </div>
          </div>
        </div>
        <div class="bg-primary text-white py-2">
          <font-awesome-icon icon="user-friends" class="mx-2" /> {{ attraction.count }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['attraction']
}
</script>

<style lang="scss">
.attraction-card {
  height: 24rem;
  width: 20rem;
}
</style>
