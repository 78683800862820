<template>
  <div class="flex flex-col">
    <navbar />

    <div class="p-8">
      <transition-group name="flip-list" tag="div" class="flex flex-wrap justify-center">
        <attraction
          v-for="attraction in attractions_sorted"
          :key="attraction.id"
          :attraction="attraction"
        />
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss">
.flip-list-move {
  transition: transform 1s;
}
</style>

<script>
import store from '../../store'
import Attraction from '@/components/Attraction'
import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
    Attraction
  },
  computed: {
    parkId () {
      return Number(this.$route.params.park)
    },
    attractions_sorted () {
      const attractions = store.attractions
        .filter(attraction => attraction.park_id === this.parkId)
        .map((attraction) => {
          const count = this.count(attraction.id)

          return {
            count,
            ...attraction
          }
        })

      return attractions.sort((a, b) => {
        return b.count - a.count
      })
    }
  },
  methods: {
    count (attractionId) {
      return store.queues.reduce((carry, queue) => {
        if (queue.attraction.id !== attractionId) return carry
        else return carry + queue.visitors.length
      }, 0)
    }
  }
}
</script>
